import React, { Component } from "react";
import PropTypes from "prop-types";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import classNames from "classnames";

import EditButton from "../../../backend/edit-button";
import Image from "../../image/image";
import { teaserEventPropTypes } from "./teaser-event";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{adminApp: *}}
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  adminApp: reduxStore.appStore.adminApp,
});

class TeaserEventEinzelDoppel extends Component {
  static defaultProps = { pagerFullPage: false };

  render() {
    return (
      <article
        className={`node node-${this.props.item.entityId} node-teaser teaser-veranstaltung teaser-doppel-einzel`}
      >
        <EditButton
          adminApp={this.props.adminApp}
          entityId={this.props.item.entityId}
          destinationRoute={this.props.location.pathname}
        />
        <div
          className={classNames({
            row: true,
            "flex-row-reverse": this.props.single,
          })}
        >
          <div
            className={classNames({
              "col-10": true,
              "col-md-8": this.props.single,
            })}
          >
            {this.props.item.fieldBild !== null && (
              <Image
                data={this.props.item.fieldBild.entity.fieldMediaImage}
                nodeTitle={this.props.item.title}
              />
            )}
          </div>

          <div
            className={classNames({
              "col-10": true,
              "col-md-8": this.props.single,
            })}
          >
            <div className="row">
              <div className="col-10 col-md-4">
                <div className="meta-date top-line">
                  <span>
                    {moment
                      .utc(this.props.item.fieldDatum.value)
                      .local()
                      .format("ddd")}
                  </span>
                  {moment
                    .utc(this.props.item.fieldDatum.value)
                    .local()
                    .format("HH:mm") !== "00:00" && (
                    <span>
                      {moment
                        .utc(this.props.item.fieldDatum.value)
                        .local()
                        .format("HH:mm")}{" "}
                      Uhr
                    </span>
                  )}
                </div>
                <div className="date">
                  <span>
                    {moment
                      .utc(this.props.item.fieldDatum.value)
                      .local()
                      .format("DD.MM.YYYY")}
                  </span>
                </div>
                <div className="location">{this.props.item.fieldOrt}</div>
                {this.props.item.fieldDatum.endValue &&
                  !this.props.item.fieldDauerAusblenden && (
                    <div className="duration">
                      Dauer{" "}
                      {Math.round(
                        moment
                          .duration(
                            moment
                              .utc(this.props.item.fieldDatum.endValue)
                              .local()
                              .diff(
                                moment
                                  .utc(this.props.item.fieldDatum.value)
                                  .local()
                              )
                          )
                          .asHours()
                      )}{" "}
                      Std.
                    </div>
                  )}
                <div className="buttons">
                  <a href="#" className="btn btn-primary">
                    Merken
                  </a>
                  {this.props.item.fieldTicketlink && (
                    <a
                      href={this.props.item.fieldTicketlink.url.path}
                      className="btn btn-primary"
                    >
                      Ticket
                    </a>
                  )}
                </div>
              </div>
              <div className="col-10 col-md-12">
                {this.props.item.fieldSchlagwort.length > 0 && (
                  <span className="top-line">
                    {this.props.item.fieldSchlagwort[0].entity.name}
                  </span>
                )}
                <h3>{this.props.item.title}</h3>
                <div
                  className="text teaser-text text d-none d-lg-block"
                  dangerouslySetInnerHTML={{
                    __html: `${this.props.item.fieldText.processed} <a href="${this.props.item.path.alias}">weiterlesen</a>`,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </article>
    );
  }
}

TeaserEventEinzelDoppel.propTypes = {
  adminApp: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  item: teaserEventPropTypes,
  location: PropTypes.object.isRequired,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
  single: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(withRouter(TeaserEventEinzelDoppel));
