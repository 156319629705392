import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

import ErrorBoundary from "../../../../error-boundary";
import Image from "../../../image/image";

class ParagraphAkkordionText extends Component {
  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-akkordion-text": true,
    });

    let showCollapse = this.props.content.fieldAbschnitte.filter((item) => !item.entity.status).length !== this.props.content.fieldAbschnitte.length;

    if (showCollapse) {
      return (
        <section className={sectionClassNames}>
          <div className="container">
            <div className="row">
              <div className="col-10 col-lg-8 offset-lg-2">
                {this.props.content.fieldTitel && (
                  <h2 className="collapse-title">
                    {this.props.content.fieldTitel}
                  </h2>
                )}
                <Accordion allowMultipleExpanded={true} allowZeroExpanded={true}>
                  {this.props.content.fieldAbschnitte.map((item, index) => (
                    <ErrorBoundary key={index}>
                      {item.entity.status &&
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              {item.entity.fieldTitel &&
                                <h3 className="main-title">{item.entity.fieldTitel}</h3>
                              }
                              {item.entity.fieldSubtitel &&
                                <h4 className="sub-title">{item.entity.fieldSubtitel}</h4>
                              }
                              <span className="inktrap icon">↓</span>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <div
                              className={`accordion-panel-wrapper image-${item.entity.fieldAccordionImagePosition || "left"}`}
                            >
                              {item.entity.fieldImage && (
                                <div className="image-wrapper">
                                  <Image
                                    data={{
                                      ...item.entity.fieldImage.entity.fieldMediaImage,
                                      style: (() => {
                                        switch (item.entity.fieldAccordionImagePosition) {
                                          case "top":
                                            return item.entity.fieldImage.entity.fieldMediaImage.styleStandard
                                          case "left":
                                          default:
                                            return item.entity.fieldImage.entity.fieldMediaImage.styleSmall
                                        }
                                      })()
                                    }}
                                    nodeTitle={item.entity.fieldTitel}
                                  />
                                </div>
                              )}
                              <div className="text-wrapper">
                                <div
                                  className="text"
                                  dangerouslySetInnerHTML={{
                                    __html: item.entity.fieldText.processed,
                                  }}
                                />
                              </div>
                            </div>
                          </AccordionItemPanel>
                        </AccordionItem>
                      }
                    </ErrorBoundary>
                  ))}
                </Accordion>
              </div>
            </div>
          </div>
        </section>
      );
    }
  }
}

ParagraphAkkordionText.propTypes = {
  content: PropTypes.shape({
    fieldAbschnitte: PropTypes.arrayOf(
      PropTypes.shape({
        entity: PropTypes.shape({
          fieldTitel: PropTypes.string,
          fieldSubtitel: PropTypes.string,
          fieldText: PropTypes.shape({
            processed: PropTypes.string,
          }),
        }),
      })
    ),
  }),
};

export default ParagraphAkkordionText;
