const isPrimitive = (value) => typeof value !== "object";
const isArray = (value) => Array.isArray(value);
const isObject = (value) => !Array.isArray(value) && typeof value === "object";

/**
 * Custom hook to manage URL search parameters.
 *
 * @returns {[URLSearchParams, function]} An array containing the URLSearchParams object and a function to set search parameters.
 */
const useSearchParams = () => {
  const searchParams = new URLSearchParams(
    typeof window !== "undefined" ? window.location.search : null
  );

  const setSearchParams = (key, value) => {
    if (value === null || value === "" || value?.length === 0) {
      searchParams.delete(key);
    } else {
      console.log(key, value);
      if (isPrimitive(value)) {
        searchParams.set(key, value);
      }

      if (isArray(value)) {
        searchParams.delete(key);
        value.forEach((v) => searchParams.append(key, v));
      }

      if (isObject(value)) {
        searchParams.set(key, JSON.stringify(value));
      }
    }
    window.history.pushState(
      {},
      "",
      `${window.location.pathname}?${searchParams.toString()}`
    );
  };

  return [searchParams, setSearchParams];
};

export default useSearchParams;
