import React, { useRef } from "react";
import PropTypes from "prop-types";
import { Search, Button } from "carbon-components-react";
import useSearchParams from "../../../../../hooks/use-search-params";

const ComponentExtendedTeaserOverviewSearch = (props) => {
  const search = useRef();
  const [searchParams] = useSearchParams();

  const searchHandler = () => {
    const value =
      typeof value === "undefined" || value.length < 3
        ? search.current.input.value
        : "";

    props.changeActiveSearch(search.current.input.value);
  };

  return (
    <section className="search">
      <Search
        ref={search}
        id="search-1"
        placeHolderText="Suchen & Finden"
        size="xl"
        onChange={searchHandler}
        labelText="Suche..."
        defaultValue={searchParams.get("search")}
      />
      <Button className="sr-only" onClick={searchHandler}>
        Suchen
      </Button>
    </section>
  );
};

ComponentExtendedTeaserOverviewSearch.propTypes = {
  changeActiveSearch: PropTypes.func.isRequired,
};

export default ComponentExtendedTeaserOverviewSearch;
