import React, { useRef, useState } from "react";
import Image from "../../../image/image";
import Slider from "react-slick";
import ErrorBoundary from "../../../../error-boundary";
import classNames from "classnames";
import SlideContent from "../teaserliste/components/slide-content";

const GallerySlider = (props) => {
  const [slideIndex, setSlideIndex] = useState(0);

  const updateSlideIndex = (next) => {
    setSlideIndex(next);
  }

  const sliderRef = useRef(null),
    sliderComponentRef = useRef(null);

  const NextArrow = ({ className, onClick }) => {
    return (
      <div
        className={className}
        onClick={onClick}
      >
        →
      </div>
    );
  }

  const PrevArrow = ({ className, onClick }) => {
    return (
      <div
        className={className}
        onClick={onClick}
      >
        ←
      </div>
    );
  }

  const sliderSettings = {
    arrows: true,
    dots: false,
    fade: false,
    autoplay: props.loopSlider,
    autoplaySpeed: props?.autoplaySpeed ? Number(props.autoplaySpeed) * 1000 : 5000,
    pauseOnHover: props.loopSlider,
    slidesToShow: 1,
    variableWidth: false,
    variableHeight: false,
    slidesToScroll: 1,
    focusOnSelect: false,
    touchMove: true,
    waitForAnimate: false,
    nextArrow: <NextArrow/>,
    prevArrow: <PrevArrow/>,
    beforeChange: (current, next) => {
      updateSlideIndex(next)
    },
  }

  return (
    <div
      className={classNames({
        "custom-slider-component": true
      })}
      ref={sliderComponentRef}
    >
      <div className={"slider-wrapper"}>
        <Slider {...sliderSettings} ref={sliderRef}>
          {props.content.map((item, index) =>
            <ErrorBoundary>
              {item.entity.fieldMediaImage &&
                <>
                  <article className={`media-image ${item.entity.fieldMediaImage.style.height / item.entity.fieldMediaImage.style.width > 1.2 ? 'portrait' : 'landscape'}`} key={index}>
                    <Image
                      data={item.entity.fieldMediaImage}
                      fullPage={true}
                      customCaption={props.globalCaption}
                      inSlider={true}
                    />
                  </article>
                  {!props.globalCaption && (item.customCaption || item.entity.fieldBildunterschrift || item.entity.fieldCredit) &&
                    <div className="info-wrap">
                      {(item.customCaption || item.entity.fieldBildunterschrift) &&
                        <span className="caption">
                        {item.customCaption ? (
                          <>
                            {item.customCaption}
                          </>
                        ) : (
                          <>
                            {item.entity.fieldBildunterschrift}
                          </>
                        )}
                    </span>
                      }
                      {item.entity.fieldCredit &&
                        <span className="credit">© {item.entity.fieldCredit}</span>
                      }
                    </div>
                  }
                </>
              }
            </ErrorBoundary>
          )}
        </Slider>
      </div>
      <div className="info-box">
        <div className="container">
          <div className="row">
            <div className="infos col-7 col-md-8">
              {props.globalCaption &&
                <div className="global-caption">
                  <span className="caption">{props.globalCaption}</span>
                </div>
              }
            </div>
            <div className="counter col-3 col-md-2">
              {slideIndex < 10 && 0}{slideIndex + 1} / {props.content.length < 10 && 0}{props.content.length}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GallerySlider;