import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { graphql } from "@apollo/client/react/hoc";
import ReactPaginate from "react-paginate";
import { animateScroll as scroll } from "react-scroll";
import { connect } from "react-redux";
import { compose } from "recompose";
import moment from "moment";
import Masonry from "react-masonry-component";

import teaserNodeQueryEventTagFiltered from "../../../teaser-base/queries/teaser-node-query-event-tag-filtered.graphql";
import teaserNodeQueryFilterTag from "../../../teaser-base/queries/teaser-node-query-tag-filtered.graphql";
import TeaserPersonOverview from "../../../teaser-base/person/teaser-person-overview";
import TeaserNews from "../../../teaser-base/news/teaser-news";
import ComponentFilterCloud from "./components/component-filter-cloud";
import TeaserAngebot from "../../../teaser-base/angebot/teaser-angebot";
import TeaserKindergeburtstag from "../../../teaser-base/kindergeburtstag/teaser-kindergeburtstag";
import TeaserKurs from "../../../teaser-base/kurs/teaser-kurs";
import { pagerFullPagesAction } from "../../../../app-actions";
import ComponentExtendedTeaserOverviewFilterText from "./components/component-extended-teaser-overview-filter-text";
import ComponentExtendedTeaserOverviewFilterDialogBaum from "./components/component-extended-teaser-overview-filter-dialog-baum";
import ComponentExtendedTeaserOverviewSearch from "./components/component-extended-teaser-overview-search";
import ErrorBoundary from "../../../../error-boundary";
import TeaserEvent from "../../../teaser-base/event/teaser-event";
import TeaserProjekt from "../../../teaser-base/project/teaser-projekt";
import { Accordion, AccordionItem } from "react-accessible-accordion";
import TeaserHighlight from "../../../teaser-base/highlight/teaser-highlight";
import KursOverview from "./kurs/kurs-overview";
import PersonOverviewAccordions from "./persons/person-overview-accordions";
import { encodeUrl } from "../../../../lib/encode-url";
import ScrollUpButton from "react-scroll-up-button";
import ScrollToTop from "../../../scroll-to-top";
import useSearchParams from "../../../../hooks/use-search-params";

const logicalConcatenation = (val1, val2, operator) => {
  switch (operator) {
    case "AND":
      return val1 && val2;
    case "OR":
      return val1 || val2;
  };
}

const ParagraphExtendedTeaserOverview = ({
  filterCloudFilters = ["timeFilters", "fieldZielgruppe", "fieldSchlagwort"],
  filterCloudFilterSingle = false,
  filterCloudMultipleFilterCondition = "AND",
  ...props
}) => {
  /* static defaultProps = {
    skipQuery: false,
    filterCloudLabels: [null, "Suche nach Tags"],
    filterCloudFilters: ["timeFilters", "fieldZielgruppe", "fieldSchlagwort"],
    filterCloudFilterSingle: false,
    filterCloudMultipleFilterCondition: "AND",
    filterCloudPreventEmptyResult: true,
    usePager: false
  }; */

  const [searchParams, setSearchParams] = useSearchParams();

  const [activeFilter, setActiveFilter] = useState(
    searchParams.getAll("filter_cloud").map((item) => ({
      field: item.split("_")[0],
      entityId: item.split("_")[1],
    })) || []
  );
  const [activeSearch, setActiveSearch] = useState(
    searchParams.get("search") || ""
  );
  const [dayFilter, setDayFilter] = useState(searchParams.getAll("day") || []);
  const [monthFilter, setMonthFilter] = useState(
    searchParams.getAll("month") || []
  );
  const [ferienFilter, setFerienFilter] = useState(
    searchParams.get("ferien") === "true"
  );

  const filterForDay = (nodes, dayFilter) => {
    let filteredNodes = nodes.filter((item) => {
      return item.fieldTagesfilter.some((day) => dayFilter.includes(day));
    });

    return filteredNodes;
  };

  const filterForMonth = (nodes, monthFilter) => {
    const unifyDate = (date) => {
      return +moment(date).format("YYYYMM");
    };

    let filteredNodes = nodes.filter((item) => {
      const itemStartDate = unifyDate(item.fieldDatumKurs.value);
      const itemEndDate = unifyDate(item.fieldDatumKurs.endValue);

      const monthMatches = monthFilter.some((selectedMonth) => {
        const unifiedSelectedMonth = unifyDate(selectedMonth);
        return (
          unifiedSelectedMonth >= itemStartDate &&
          unifiedSelectedMonth <= itemEndDate
        );
      });

      return monthMatches;
    });

    return filteredNodes;
  };

  const filterItems = () => {
    let filteredItems = [...props.nodes.nodeQuery.entities];

    // Filter for day
    if (dayFilter?.length > 0) {
      filteredItems = filterForDay(filteredItems, dayFilter);
    }

    // Filter for month
    if (monthFilter?.length > 0) {
      filteredItems = filterForMonth(filteredItems, monthFilter);
    }

    // Filter for Ferien
    if (ferienFilter) {
      filteredItems = filteredItems.filter((item) => {
        return item.fieldFerien;
      });
    }

    // Filter cloud
    if (activeFilter.length > 0) {
      filteredItems = filteredItems.filter((item) => {
        let match;

        if (filterCloudMultipleFilterCondition === "OR") {
          match = false;
        }
        if (filterCloudMultipleFilterCondition === "AND") {
          match = true;
        }

        for (const activeFilterItem of activeFilter) {
          if (item[activeFilterItem.field] == null) {
            continue;
          }

          // Filtering on string-based fields.
          if (typeof item[activeFilterItem.field] === "string") {
            if (item[activeFilterItem.field] === activeFilterItem.entityId) {
              match = logicalConcatenation(match, true, filterCloudMultipleFilterCondition);
            } else {
              match = logicalConcatenation(match, false, filterCloudMultipleFilterCondition);
            }

            continue;
          }

          // Single Entity reference fields.
          if (item[activeFilterItem.field].entity) {
            if (
              item[activeFilterItem.field].entity.entityId ===
              activeFilterItem.entityId
            ) {
              match = logicalConcatenation(match, true, filterCloudMultipleFilterCondition);
            } else {
              match = logicalConcatenation(match, false, filterCloudMultipleFilterCondition);
            }
          }

          // Multi Entity reference fields.
          if (
            item[activeFilterItem.field].some(
              (tag) =>
                tag.entity && tag.entity.entityId === activeFilterItem.entityId
            )
          ) {
            match = logicalConcatenation(match, true, filterCloudMultipleFilterCondition);
          } else {
            match = logicalConcatenation(match, false, filterCloudMultipleFilterCondition);
          }
        }
        return match;
      });
    }

    // Search.
    if (activeSearch) {
      filteredItems = filteredItems.filter((item) => {
        const parsedItem = JSON.stringify(item).toLowerCase();
        return parsedItem.includes(activeSearch);
      });
    }

    return filteredItems;
  };

  const getItemsToShow = (start, end) => {
    if (!props.nodes.loading && props.nodes.nodeQuery.entities.length >= 1) {
      if (!end) {
        end = props.nodes.nodeQuery.entities.length;
      }

      const items = filterItems();

      if (props.usePager) {
        return items.slice(start, end);
      }

      return items;
    }

    return [];
  };

  const [itemsMatching, setItemsMatching] = useState(getItemsToShow(0));
  const [itemsToShow, setItemsToShow] = useState(
    getItemsToShow(
      0,
      props.usePager ? props.content.fieldElementeProSeite : null
    )
  );

  const itemSection = useRef();

  const redraw = () => {
    setItemsMatching(getItemsToShow(0));
    setItemsToShow(
      getItemsToShow(
        0,
        props.usePager ? props.content.fieldElementeProSeite : null
      )
    );
  };

  const pushPagerFullPageConfig = () => {
    if (props.content.fieldPagerAufVollseiten && props.nodes.nodeQuery) {
      const pagerFullPagesConfig = {
        id: props.content.entityId,
        items: props.nodes.nodeQuery.entities,
        overviewLink: props.content.fieldLinkZurUebersicht,
      };

      props.dispatch(pagerFullPagesAction(pagerFullPagesConfig));
    }
  };

  useEffect(() => {
    redraw();
    pushPagerFullPageConfig();
  }, [props.nodes]);

  useEffect(() => {
    redraw();
  }, [activeFilter, activeSearch, dayFilter, monthFilter, ferienFilter]);

  /**
   * Reset all or specific filers.
   * @param {string} field Field to reset filters for
   */
  const resetFilter = (field = "") => {
    // by default, all filters get resetted
    let updatedFilter = [];
    // if field is specified, only reset filters for this field
    if (field) {
      updatedFilter = activeFilter.filter((item) => {
        return item.field !== field;
      });
    }
    setSearchParams(
      "filter_cloud",
      updatedFilter.map((item) => `${item.field}_${item.entityId}`)
    );
    setActiveFilter(updatedFilter);
  };

  const changeActiveFilter = (_activeFilter) => {
    if (
      activeFilter &&
      activeFilter.filter(
        (item) =>
          item.entityId === _activeFilter.entityId &&
          item.field === _activeFilter.field
      ).length > 0
    ) {
      setActiveFilter(null);
    } else {
      setActiveFilter({ ...activeFilter, _activeFilter });
    }
    history.replaceState(null, null, " ");
  };

  const changeActiveFilterCloud = (_activeFilter) => {
    history.replaceState(null, null, " ");

    // First check "only one filter at a time" option.
    if (filterCloudFilterSingle) {
      if (
        activeFilter.length > 0 &&
        activeFilter[0].entityId === _activeFilter.entityId
      ) {
        setActiveFilter([]);
      } else {
        setActiveFilter([_activeFilter]);
      }

      return;
    }

    let newActiveFilter = activeFilter;
    if (
      activeFilter &&
      activeFilter.filter(
        (item) =>
          item.entityId === _activeFilter.entityId &&
          item.field === _activeFilter.field
      ).length > 0
    ) {
      // Remove filter from active filters.
      newActiveFilter = activeFilter.filter(
        (item) =>
          item.entityId !== _activeFilter.entityId ||
          item.field !== _activeFilter.field
      );
    } else {
      // Add filter to active filters.
      newActiveFilter = [...activeFilter, _activeFilter];
    }
    setActiveFilter(newActiveFilter);
    setSearchParams(
      "filter_cloud",
      newActiveFilter.map((item) => `${item.field}_${item.entityId}`)
    );
  };

  const changeActiveSearch = (searchValue) => {
    setActiveSearch(searchValue?.toLowerCase() || "");
    setSearchParams("search", searchValue?.toLowerCase() || "");
  };

  // Filter is initialized and set in component-filter-cloud
  const changeMonthFilter = (month) => {
    let _monthFilter = [...monthFilter];

    if (month === "reset") {
      _monthFilter = [];
    } else {
      if (_monthFilter.indexOf(month) > -1) {
        // remove element
        _monthFilter.splice(_monthFilter.indexOf(month), 1);
      } else {
        // add element
        _monthFilter = [..._monthFilter, month];
      }
    }
    setSearchParams("month", _monthFilter);
    setMonthFilter(_monthFilter);
  };

  // Filter is initialized and set in component-filter-cloud
  const changeDayFilter = (day) => {
    let _dayFilter = [...dayFilter];

    if (day === "reset") {
      _dayFilter = [];
    } else {
      if (_dayFilter.indexOf(day) > -1) {
        // remove element
        _dayFilter.splice(_dayFilter.indexOf(day), 1);
      } else {
        // add element
        _dayFilter = [..._dayFilter, day];
      }
    }
    setSearchParams("day", _dayFilter);
    setDayFilter(_dayFilter);
  };

  // Filter is initialized and set in component-filter-cloud
  const changeFerienFilter = () => {
    const newFerienFilter = !ferienFilter;

    // Originally this was set to setSearchParams("ferien", newFerienFilter); which is basically also correct, but the client wants to have it removed when not clicked.
    setSearchParams("ferien", newFerienFilter || []);
    setFerienFilter(newFerienFilter);
  };

  const handlePageClick = (data) => {
    setItemsToShow(
      getItemsToShow(
        data.selected * props.content.fieldElementeProSeite,
        data.selected * props.content.fieldElementeProSeite +
          props.content.fieldElementeProSeite
      )
    );
    scroll.scrollTo(itemSection.current.offsetTop);
  };

  const sectionClassNames = classNames({
    "paragraph paragraph-extended-teaser-overview": true,
    [`type-${props.content.fieldTypExtended}`]: true,
  });

  return (
    <section className={sectionClassNames} ref={itemSection}>
      {!props.nodes.loading &&
        !!props.nodes.nodeQuery.entities.length &&
        props.content.fieldInhaltstyp && (
          <ErrorBoundary>
            <ComponentFilterCloud
              items={itemsToShow}
              filter={filterCloudFilters}
              labels={props.filterCloudLabels}
              activeFilter={activeFilter}
              filterItems={filterItems}
              preventEmptyResult={true}
              changeActiveFilter={changeActiveFilterCloud}
              resetFilter={resetFilter}
              changeMonthFilter={changeMonthFilter}
              monthFilter={monthFilter}
              changeDayFilter={changeDayFilter}
              dayFilter={dayFilter}
              changeFerienFilter={changeFerienFilter}
              ferienFilter={ferienFilter}
              resetFilterOn={true}
              type={props.content.entityBundle}
              changeActiveSearch={changeActiveSearch}
            />
          </ErrorBoundary>
        )}
      {/*!this.props.nodes.loading &&
          this.props.nodes.nodeQuery.entities.length > 1 &&
          (this.props.content.fieldFilterwolke ||
            this.props.content.fieldInhaltstyp) &&
          this.props.content.fieldInhaltstyp != "kurs" && (
            <ErrorBoundary>
              <ComponentFilterCloud
                items={itemsMatching}
                filter={
                  this.props.content.fieldInhaltstyp === "kurs"
                    ? "fieldKurstyp"
                    : "fieldSchlagwort"
                }
                activeFilter={activeFilter}
                changeActiveFilter={this.changeActiveFilter}
              />
            </ErrorBoundary>
              )*/}

      {!props.nodes.loading &&
        props.nodes.nodeQuery.entities.length > 1 &&
        props.content.fieldFilterImText && (
          <ErrorBoundary>
            <ComponentExtendedTeaserOverviewFilterText
              items={props.content.fieldFilterImTextReference}
              filter="fieldSchlagwort"
              activeFilter={activeFilter}
              changeActiveFilter={changeActiveFilter}
            />
          </ErrorBoundary>
        )}

      {!props.nodes.loading &&
        props.nodes.nodeQuery.entities.length > 1 &&
        props.content.fieldFilterDialogBaum && (
          <ErrorBoundary>
            <ComponentExtendedTeaserOverviewFilterDialogBaum
              items={props.content.fieldFilterDialogBaumReferen}
              activeFilter={activeFilter}
              changeActiveFilter={changeActiveFilter}
            />
          </ErrorBoundary>
        )}

      {props.content.fieldInhaltstyp ? (
        <div
          className={
            props.content.fieldInhaltstyp === "kurs"
              ? "kursuebersicht"
              : "angebotsuebersicht"
          }
        >
          <div className="container legend-wrapper">
            <div className={`row`}>
              {props.content.fieldInhaltstyp === "kindergeburtstag" && (
                <>
                  <div className="col-10 col-md-6 legend grow">Format</div>
                  <div className="col-10 col-md-2 legend">Altersempfehlung</div>
                  <div className="col-10 col-md-1 legend info">Info</div>
                </>
              )}
              {props.content.fieldInhaltstyp === "angebot" && (
                <>
                  <div className="col-10 col-md-1 legend">Nummer</div>
                  <div className="col-10 col-md-5 legend grow">Format</div>
                  <div className="col-10 col-md-3 legend">Altersempfehlung</div>
                  <div className="col-10 col-md-1 legend info">Info</div>
                </>
              )}
              {props.content.fieldInhaltstyp === "kurs" && (
                <>
                  <div className="col-10 col-md-2 legend">Tag, Datum</div>
                  <div className="col-10 col-md-1 col-lg-3 legend">Format</div>
                  <div className="col-10 col-md-4 col-lg-3 legend">Uhrzeit</div>
                  <div className="col-10 col-md-2 col-lg-1 legend">
                    Altersempfehlung
                  </div>
                  <div className="col-10 col-md-1 legend">Info</div>
                </>
              )}
            </div>
          </div>
          <div className="container">
            <div className={`row`}>
              {itemsToShow.length >= 1 &&
                props.content.fieldInhaltstyp !== "kurs" &&
                itemsToShow.map((item, index) => (
                  <React.Fragment key={index}>
                    {(() => {
                      switch (item?.entityBundle) {
                        case "kindergeburtstag":
                          return (
                            <div className="col-10 teaser-wrapper">
                              <ErrorBoundary>
                                <TeaserKindergeburtstag
                                  item={item}
                                  pagerFullPage={
                                    props.content.fieldPagerAufVollseiten
                                      ? props.content.entityId
                                      : false
                                  }
                                />
                              </ErrorBoundary>
                            </div>
                          );
                        case "angebot":
                          return (
                            <div className="col-10 teaser-wrapper">
                              <ErrorBoundary>
                                <TeaserAngebot
                                  item={item}
                                  pagerFullPage={
                                    props.content.fieldPagerAufVollseiten
                                      ? props.content.entityId
                                      : false
                                  }
                                />
                              </ErrorBoundary>
                            </div>
                          );
                        default:
                          return null;
                      }
                    })()}
                  </React.Fragment>
                ))}
            </div>
          </div>
          {props.content.fieldInhaltstyp === "kurs" && (
            <div className="item-wrapper">
              <KursOverview
                itemsToShow={itemsToShow}
                content={props.content}
                usePager={true}
                loading={props.nodes.loading}
                error={props.nodes.error}
              />
            </div>
          )}
        </div>
      ) : (
        <div className="container">
          {props.content.fieldTypExtended === "person" ? (
            itemsToShow.length >= 1 && (
              <div className="row">
                <div className="col-10 col-lg-8 offset-lg-2">
                  {props.content.fieldShowTitle &&
                    (props.content.fieldTitle ? (
                      <h2>{props.content.fieldTitle}</h2>
                    ) : (
                      props.content.fieldSchlagwort && (
                        <h2>
                          {props.content.fieldSchlagwort
                            .map((tag) => tag.entity.entityLabel)
                            .join(" & ")}
                        </h2>
                      )
                    ))}
                  {itemsToShow && itemsToShow.length >= 1 && (
                    <PersonOverviewAccordions
                      itemsToShow={itemsToShow}
                      location={props.location}
                    />
                  )}
                </div>
              </div>
            )
          ) : (
            <Masonry className={`row`}>
              {itemsToShow.length >= 1 &&
                itemsToShow.map((item, index) => (
                  <React.Fragment key={index}>
                    {(() => {
                      switch (item?.entityBundle) {
                        case "news":
                          return (
                            <div className="col-10 col-md-4 offset-md-1">
                              <ErrorBoundary>
                                <TeaserNews
                                  item={item}
                                  pagerFullPage={
                                    props.content.fieldPagerAufVollseiten
                                      ? props.content.entityId
                                      : false
                                  }
                                />
                              </ErrorBoundary>
                            </div>
                          );
                        case "projekt":
                          return (
                            <div
                              className={classNames({
                                "col-10 col-md-custom": true,
                              })}
                            >
                              <ErrorBoundary>
                                <TeaserProjekt
                                  item={item}
                                  pagerFullPage={
                                    props.content.fieldPagerAufVollseiten
                                      ? props.content.entityId
                                      : false
                                  }
                                />
                              </ErrorBoundary>
                            </div>
                          );

                        case "veranstaltung":
                          return <TeaserEvent item={item} />;

                        case "highlight": {
                          if (item.status) {
                            return (
                              <div className="col-10 col-md-custom teaser-wrapper">
                                <ErrorBoundary>
                                  <TeaserHighlight item={item} />
                                </ErrorBoundary>
                              </div>
                            );
                          }
                          return;
                        }

                        default:
                          return null;
                      }
                    })()}
                  </React.Fragment>
                ))}
            </Masonry>
          )}
        </div>
      )}

      {props.content.entityBundle === "kurse_uebersicht" && <ScrollToTop />}

      {!props.nodes.loading &&
        props.nodes.nodeQuery.entities.length >
          props.content.fieldElementeProSeite &&
        props.usePager && (
          <div className="container">
            <div className="row">
              <div className="col-10">
                <div className="pagination">
                  <ErrorBoundary>
                    <ReactPaginate
                      onPageChange={handlePageClick}
                      pageCount={Math.ceil(
                        itemsMatching.length /
                          props.content.fieldElementeProSeite
                      )}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      previousLabel={"Vorherige Seite"}
                      nextLabel={"Nächste Seite"}
                      previousClassName="btn btn-primary previous"
                      nextClassName="btn btn-primary next"
                    />
                  </ErrorBoundary>
                </div>
              </div>
            </div>
          </div>
        )}
    </section>
  );
};

ParagraphExtendedTeaserOverview.propTypes = {
  content: PropTypes.shape({
    entityId: PropTypes.string,
    fieldDarstellung: PropTypes.oneOf(["small_big", "small_highlighted"]),
    fieldElementeProSeite: PropTypes.number,
    fieldPagerAufVollseiten: PropTypes.bool,
    fieldLinkZurUebersicht: PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.shape({
        path: PropTypes.string,
        routed: PropTypes.string,
      }),
    }),
    fieldTypExtended: PropTypes.oneOf([
      "news",
      "person",
      "projekt",
      "veranstaltung",
      "highlight",
    ]),
    fieldPagerVerwenden: PropTypes.bool,
    fieldFilterwolke: PropTypes.bool,
    fieldFilterImText: PropTypes.bool,
    fieldFilterDialogBaum: PropTypes.bool,
    fieldSucheAktivieren: PropTypes.bool,
    fieldFilterImTextReference: PropTypes.arrayOf(
      PropTypes.shape({
        entity: PropTypes.shape({
          entityBundle: PropTypes.oneOf([
            "filtertext_text",
            "filtertext_filter",
          ]),
          fieldFilterTextText: PropTypes.string,
          fieldFilter: PropTypes.shape({
            entity: PropTypes.shape({
              entityLabel: PropTypes.string,
              entityId: PropTypes.string,
            }),
          }),
        }),
      })
    ),
    fieldFilterDialogBaumReferen: PropTypes.arrayOf(
      PropTypes.shape({
        entity: PropTypes.shape({
          entityId: PropTypes.string,
          entityBundle: PropTypes.oneOf(["filtertext_text", "filteroptionen"]),
          fieldFilterTextText: PropTypes.string,
          fieldFilterMultiple: PropTypes.arrayOf(
            PropTypes.shape({
              targetId: PropTypes.string,
              entity: PropTypes.shape({
                entityLabel: PropTypes.string,
              }),
            })
          ),
        }),
      })
    ),
    fieldSchlagwort: PropTypes.arrayOf(
      PropTypes.shape({
        targetId: PropTypes.string,
      })
    ),
  }),
  dispatch: PropTypes.func.isRequired,
  nodes: PropTypes.object.isRequired,
  skipQuery: PropTypes.bool,
};

/* TODO: Create own components for extended teaser paragraphs and add own
 *   queries for each component to prevent chaos.
 * TODO: Refactor all teaserNodeQueries - work with fragments and just make
 *  the conditions and sorting unique for the queries. */
export default compose(
  graphql(teaserNodeQueryFilterTag, {
    name: "nodes",
    skip: (props) =>
      props.content.fieldManuelleSortierung ||
      props.content.fieldTypExtended === "veranstaltung" ||
      props.skipQuery,
    options: (props) => ({
      variables: {
        limit: 1000,
        type: [
          props.content.fieldInhaltstyp
            ? props.content.fieldInhaltstyp
            : props.content.fieldTypExtended,
        ],
        tag: props.content.fieldSchlagwort
          ? props.content.fieldSchlagwort.map((item) =>
              item.targetId.toString()
            )
          : false,
        filterTagEnabled:
          props.content.fieldSchlagwort &&
          props.content.fieldSchlagwort.length > 0,
        department:
          props.content.fieldDepartment &&
          props.content.fieldDepartment.targetId.toString(),
      },
    }),
  }),
  graphql(teaserNodeQueryEventTagFiltered, {
    name: "nodes",
    skip: (props) =>
      props.content.fieldTypExtended !== "veranstaltung" ||
      props.content.fieldManuelleSortierung ||
      props.skipQuery,
    options: (props) => ({
      variables: {
        date: moment().format("YYYY-MM-DD"),
        limit: 10000,
        type: [props.content.fieldTypExtended],
        tags: props.content.fieldSchlagwort.map((item) =>
          item.targetId.toString()
        ),
        filterTagEnabled: props.content.fieldSchlagwort.length > 0,
      },
    }),
  })
)(connect()(ParagraphExtendedTeaserOverview));
