import React, { Component } from "react";
import PropTypes from "prop-types";
import Masonry from "react-masonry-component";

import Image from "../../../image/image";
import { ArrowLeft32, ArrowRight32 } from "@carbon/icons-react";
import MasonryImages from "./masonry-images";
import GallerySlider from "./gallery-slider";

/**
 * This get called from ParagraphBilderSlider and actually renders the images.
 */

class ComponentBildSlider extends Component {
  state = {
    showMore: false,
  };

  toggleMasonry = () => {
    this.setState({
      showMore: !this.state.showMore,
    });
  };

  render() {
    return (
      <>
        {this.props.bilder.length > 1 ? (
          <>
            {this.props.showAsSlider ? (
              <GallerySlider
                content={this.props.bilder}
                loopSlider={this.props.content?.fieldLoopSlider}
                autoplaySpeed={this.props.content?.fieldAutoplaySpeed}
                globalCaption={this.props.globalCaption}
                wrapCaption={this.props.wrapCaption}
              />
            ) : (
              <MasonryImages
                bilder={this.props.bilder}
                globalCaption={this.props.globalCaption}
                wrapCaption={this.props.wrapCaption}
              />
            )}
          </>
        ) : (
          <article className="media-image">
            <Image
              data={this.props.bilder[0].entity.fieldMediaImage}
              credit={this.props.bilder[0].entity.fieldCredit}
              caption={
                this.props.bilder[0].defaultCaption === false
                  ? this.props.bilder[0].customCaption
                  : null
              }
              fullPage={true}
              globalCaption={this.props.globalCaption}
              wrapCaption={this.props.wrapCaption}
            />
          </article>
        )}
      </>
    );
  }
}

ComponentBildSlider.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      defaultCaption: PropTypes.bool,
      customCaption: PropTypes.string,
      entity: PropTypes.shape({
        fieldCredit: PropTypes.string,
        fieldMediaImage: PropTypes.shape({
          alt: PropTypes.string,
          title: PropTypes.string,
          style: PropTypes.shape({
            url: PropTypes.string.isRequired,
          }),
        }),
      }),
    })
  ),
  globalCaption: PropTypes.string,
  wrapCaption: PropTypes.bool,
};

export default ComponentBildSlider;
